export const COLOR_MAP = {
    red: { hex: "#ff0000", rgba: "rgba(255,0,0,1)" },
    orange: { hex: "#ffa500", rgba: "rgba(255,165,0,1)" },
    yellow: { hex: "#ffff00", rgba: "rgba(255,255,0,1)" },
    green: { hex: "#008000", rgba: "rgba(0,128,0,1)" },
    blue: { hex: "#0000ff", rgba: "rgba(0,0,255,1)" },
    purple: { hex: "#800080", rgba: "rgba(128,0,128,1)" },
    pink: { hex: "#ffc0cb", rgba: "rgba(255,192,203,1)" },
    brown: { hex: "#a52a2a", rgba: "rgba(165,42,42,1)" },
    black: { hex: "#000000", rgba: "rgba(0,0,0,1)" },
    white: { hex: "#ffffff", rgba: "rgba(255,255,255,1)" }
};

export function getLabeledAreaId(labeledArea) {
    const areaId = labeledArea
        .points
        .map(({ x, y }) => `${x.toFixed(3)}:${y.toFixed(3)}`)
        .join(',');
    return areaId;
}
export function getLabeledAreaView(labeledAreaDto, labelOptions, areaIndex) {
    const { AreaPoints, LabelFk } = labeledAreaDto;
    const labelOption = labelOptions?.find(r => r.Id === LabelFk);
    return {
        points: AreaPoints.map(({ X, Y }) => ({ x: X, y: Y })),
        color: labelOption?.Color,
        label: labelOption?.Name,
        index: areaIndex
    }
}
export function getLabeledAreaDto(labeledAreaView, labelOptions) {
    const { points, color, label } = labeledAreaView;
    const labelOption = labelOptions.find(r => r.Color === color && r.Name === label);
    return {
        AreaPoints: points.map(({ x, y }) => ({ X: x, Y: y })),
        LabelFk: labelOption.Id
    };
}

export const downloadFile = async (endpoint) => {
    try {
        const response = await fetch(`/api/${endpoint}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${endpoint}.txt`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};